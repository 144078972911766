import "firebase/compat/auth";
import * as firebaseui from "firebaseui";
import "firebaseui/dist/firebaseui.css";
import * as ciap from "gcip-iap";

const tosUrl = "";
const privacyPolicyUrl = "";

const platformData = {
  dev: {
    title: "AIOps Development",
    apiKey: "AIzaSyCzWjtf34iTFcY6EBn9dQe49kiPQzhLkzM",
    authDomain: "vpc-host-dev-ja882-ek938.firebaseapp.com",
    saml: {
      provider: "saml.oktamartinadev",
      providerName: "Okta",
      buttonColor: "#F00020",
    },
  },
  preprod: {
    title: "MARTINA Pre-prod",
    apiKey: "AIzaSyA8YPfnw2aMK1R-f3vWX8eW6qDR0lwgjmk",
    authDomain: "vpc-host-preprod-ja882-ek938.firebaseapp.com",
    saml: {
      provider: "saml.oktamartinapreprod",
      providerName: "Okta",
      buttonColor: "#F00020",
    },
  },
  prod: {
    title: "MARTINA Prod",
    apiKey: "AIzaSyD_QD2kV5UN1P2-kgwx_S3XsYNG9oamIik",
    authDomain: "vpc-host-prod-ja882-ek938.firebaseapp.com",
    saml: {
      provider: "saml.oktamartinaproduction",
      providerName: "Okta",
      buttonColor: "#F00020",
    },
  },
};

// const platform = process.env.PLATFORM;
const platformConfig = platformData["prod"];

const configs = {
  [`${platformConfig.apiKey}`]: {
    authDomain: platformConfig.authDomain,
    displayMode: "optionFirst",
    tenants: {
      _: {
        displayName: platformConfig.title,
        iconUrl:
          "https://www.gstatic.com/firebasejs/ui/2.0.0/images/auth/anonymous.png",
        logoUrl: "",
        buttonColor: "#007bff",
        immediateFederatedRedirect: true,
        signInFlow: "popup",
        signInOptions: [
          {
            provider: platformConfig.saml.provider,
            providerName: platformConfig.saml.providerName,
            buttonColor: platformConfig.saml.buttonColor,
          },
        ],
        tosUrl,
        privacyPolicyUrl,
      },
    },
    tosUrl,
    privacyPolicyUrl,
  },
};

const handler = new firebaseui.auth.FirebaseUiHandler(
  "#firebaseui-auth-container",
  configs
);
handler.languageCode = navigator.language.split("-")[0];
const ciapInstance = new ciap.Authentication(handler);
ciapInstance.start();
